import { Button, CircularProgress } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { FormEvent, useState } from 'react';
import { PaymentIntent } from '../generated';
import { CreditCardIcon } from '@heroicons/react/24/outline';

interface Props {
  onConfirm: () => void;
  paymentIntent: PaymentIntent;
}

export const CheckoutFormElements = ({ onConfirm }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e.preventDefault();
    setMessage(undefined);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setMessage(error?.message);
      setIsLoading(false);
      return;
    }

    onConfirm();
    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'accordion',
    paymentMethodOrder: ['apple_pay', 'google_pay', 'link', 'card'],
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      {message && (
        <div
          id="payment-message"
          style={{
            marginTop: 10,
            border: '1px solid red',
            color: 'red',
            textAlign: 'center',
            padding: 10,
          }}
        >
          {message}
        </div>
      )}
      <br />

      <Button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        variant="contained"
        fullWidth
        type="submit"
        style={{
          backgroundColor: 'black',
          border: '2px solid black',
          color: 'white',
          padding: '5px 10px',
          fontSize: 16,
        }}
        endIcon={<CreditCardIcon />}
      >
        <span id="button-text">{isLoading ? <CircularProgress size={18} /> : `Betala`}</span>
        <CreditCardIcon aria-hidden="true" className="-mr-0.5 h-5 w-5 ml-5" />
      </Button>
    </form>
  );
};
